import BaseRepository from "./base-repository";
import type { AddCartItemViewModel, CartItemViewModel, DeleteCartItemViewModel } from "@/types/models/cart/cart-item-view-model";

class CartRepository extends BaseRepository {
    async getShoppingCart(customerId: Nullable<number>) {
        try {
            return await this.get<CartItemViewModel[]>('/cart/getShoppingCart', { params: { customerId } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async addProductToCart(model: AddCartItemViewModel) {
        try {
            await this.post('/cart/addProductToCart', model);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async deleteProductFromCart(model: DeleteCartItemViewModel) {
        try {
            await this.post('/cart/deleteProductFromCart', model);
        }
        catch (error) {
            throw new apiError(error);
        }
    }
}

export default new CartRepository();