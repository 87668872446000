<template>
    <footer>
        <div class="container">
            <div class="row text-center text-md-start">
                <div class="col-md-4 col-lg-3">
                    <NuxtLink to="/" aria-label="Flameoff Coatings home page">
                        <NuxtImg class="logo d-none d-lg-block" src="/images/logo_footer.webp" alt="" width="158" height="57" loading="lazy"/>
                        <NuxtImg class="logo d-block d-md-none" src="/images/logo_footer@2x.webp" alt="" width="158" height="57" loading="lazy" />
                    </NuxtLink>
                    <div class="copyright">&copy; 2024<br />All rights reserved</div>
                </div>
                <div class="col-md-4 col-lg-2">
                    <div class="h5">Products</div>
                    <ul class="list-unstyled section-links">
                        <li>
                            <NuxtLink to="/intumescent-fire-resistant-paint">
                                Fire Barrier Paint
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink to="/fire-retardant-clear-spray">
                                FR Clear
                            </NuxtLink>
                        </li>
                    </ul>
                </div>
                <div class="col-md-4 col-lg-2">
                    <div class="h5">About</div>
                    <ul class="list-unstyled section-links">
                        <li><NuxtLink to="/about/company">Company</NuxtLink></li>
                        <li><NuxtLink to="/intumescent-paint-case-studies">Case Studies</NuxtLink></li>
                        <li><NuxtLink to="/articles">Articles</NuxtLink></li>
                        <li><NuxtLink to="/company-news">Company News</NuxtLink></li>
                    </ul>
                </div>
                <div class="col-md-4 offset-md-4 col-lg-2 offset-lg-0 col-resources">
                    <div class="h5">Resources</div>
                    <ul class="list-unstyled section-links">
                        <li class="list-item-nowrap"><NuxtLink to="/steel-coverage-calculator">Steel Coverage Calculator</NuxtLink></li>
                        <li><NuxtLink to="/testing">Testing</NuxtLink></li>
                        <li><NuxtLink to="/leed">LEED</NuxtLink></li>
                        <li><NuxtLink to="/faq">FAQ</NuxtLink></li>
                        <li><a href="https://flameoffgetcertified.tizbi.com/." target="_blank" rel="noreferrer noopener nofollow">Get Certified</a></li>
                        <li><a href="https://lunchandlearn.flameoffcoatings.com" target="_blank" rel="noreferrer noopener nofollow">Lunch and Learn</a></li>
                        <li><a href="https://www.youtube.com/@flameoffcoatingsinc5470/videos" target="_blank" rel="noreferrer noopener nofollow">Media Library</a></li>
                    </ul>
                </div>
                <div class="col-md-4 col-lg-3 col-contact-us col-md-push-4 col-lg-push-0 text-lg-end">
                    <div class="header-contact">Contact Us</div>
                    <ClientOnly>
                        <div class="text-phone">
                            <a href="tel:(888) 816-7468">(888) 816-7468</a>
                        </div>
                    </ClientOnly>
                    <a href="mailto:info@flameoffcoatings.com?bcc=flameoffdev@gmail.com&subject=[flameoffcoatings.com] General" class="mailto">info@flameoffcoatings.com</a>

                    <div class="icon-social mt-3" itemscope itemtype="https://schema.org/Organization">
                        <a itemprop="sameAs" href="https://www.linkedin.com/company/flameoff-coatings-inc" aria-label="Linkedin" target="_blank" rel="noreferrer noopener nofollow">
                            <font-awesome :icon="['fab', 'linkedin']" class="fa-lg" />
                        </a>
                        <a itemprop="sameAs" href="https://www.facebook.com/FlameOFFUS/" target="_blank" aria-label="Facebook" rel="noreferrer noopener nofollow">
                            <font-awesome :icon="['fab', 'facebook']" class="fa-lg" />
                        </a>
                        <a href="https://twitter.com/flameoffus" target="_blank" rel="noreferrer noopener nofollow" aria-label="Twitter">
                            <font-awesome :icon="['fab', 'twitter']" class="fa-lg" />
                        </a>
                        <a itemprop="sameAs" href="https://www.youtube.com/channel/UCLCRnLI3757Zczgv67pgBag" aria-label="Youtube" target="_blank" rel="noreferrer noopener nofollow">
                            <font-awesome :icon="['fab', 'youtube']" class="fa-lg" />
                        </a>
                        <a itemprop="sameAs" href="https://www.instagram.com/flameoffcoatings/" aria-label="Instagram" target="_blank" rel="noreferrer noopener nofollow">
                            <font-awesome :icon="['fab', 'instagram']" class="fa-lg" />
                        </a>
                    </div>

                    <div class="header-contact mt-5">FlameOff Estimator</div>
                    <a href="https://play.google.com/store/apps/details?id=com.flameoff.estimationV3" target="_blank" rel="noreferrer noopener nofollow">
                        <NuxtImg src="/images/google-play.webp" alt="Google Play" class="mt-3" loading="lazy" width="193" height="58"  />
                    </a>
                    <a href="https://apps.apple.com/us/app/flameoff-estimator/id6467631953" target="_blank" rel="noreferrer noopener nofollow">
                        <NuxtImg src="/images/apple-store.webp" alt="Apple App Store" class="mt-3" loading="lazy" width="193" height="58" />
                    </a>
                </div>
            </div>

            <div class="subscribe-section text-center text-md-start">
                <div class="row">
                    <div class="col-10 col-md-5 offset-1 offset-md-0">
                        <p>Get our latest news, product updates and special offers!</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-10 col-md-7 col-lg-5 col-xl-4 offset-1 offset-md-0">
                        <div class="input-group">
                            <BFormInput type="email" name="Email" v-model="subscribeEmail" class="subscribe-input" placeholder="Your email address" aria-label="Subscribe" id="subscribe" />
                            <BButton @click="subscribeNewsletter" variant="primary" class="subscribe-btn">Subscribe</BButton>
                        </div>
                        <span class="text-danger field-validation-error" v-if="showEmailError"><span class="text-danger">The Email field is required.</span></span>
                        <div class="message-form-success text-success mt-2" v-if="showwSuccessEmail">
                            <div class="header">Thank you! Your were successfully subscribed!</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="note-divider"></div>

            <div class="row footnote text-center text-lg-start">
                <div class="col">
                    <NuxtLink to="/terms-and-conditions">Terms & Conditions</NuxtLink>
                    <NuxtLink to="/privacy-policy">Privacy Policy</NuxtLink>
                    <NuxtLink to="/term-of-use">Terms of Use</NuxtLink>
                    <NuxtLink to="/shipping-policy">Shipping Policy</NuxtLink>
                    <NuxtLink to="/return-policy">Return Policy</NuxtLink>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
    import type SubscribeViewModel from '@/types/models/subscribe/subscribe-view-model';
    import messageRepository from '@/repositories/message-repository';

    const subscribeEmail = ref('');
    const showwSuccessEmail = ref(false);
    const showEmailError = ref(false);

    async function subscribeNewsletter() {
        if (!subscribeEmail.value.length)
            return showEmailError.value = true;

        const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-z0-9-](?:[A-z0-9-]*[A-z0-9])?\.)+[A-z0-9]{2,}(?:[A-z0-9-]*[A-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        if (!emailRegex.test(subscribeEmail.value))
            return;

        await messageRepository.subscribeNewsletter({ email: subscribeEmail.value } as SubscribeViewModel);

        showEmailError.value = false;
        showwSuccessEmail.value = true;
        subscribeEmail.value = '';
    }
</script>

<style lang="scss">
    @import '@/assets/styles/bootstrap/common';
    @import '@/assets/styles/_mixins';

    footer {
        width: 100%;
        padding-top: 64px;
        background-color: #151f2c;
        margin-top: 50px;

        @include media-breakpoint-up(lg) {
            position: absolute;
            bottom: 0;
            margin-top: 0;
        }

        .logo {
            display: block;
            width: 119px;
            height: 44px;
            margin: -18px auto 34px auto;

            @include media-breakpoint-up(md) {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .col-contact-us,
        .col-resources {
            @include media-breakpoint-between(md, md) {
                margin-top: 30px;
            }
        }

        .copyright {
            @include font-roboto-regular(14px);
            color: #FFF;
            line-height: 16px;
        }

        .icon-social {
            margin-top: 10px;
            display: block;

            @include media-breakpoint-up(md) {
                margin-top: 38px;
            }

            a {
                display: inline-block;
                vertical-align: middle;

                svg {
                    @include fade-animation;
                    display: inline-block;
                    color: #ffffff;
                    margin-right: 5px;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }

        @mixin header-with-red-line {
            position: relative;
            margin: 10px 0 24px 0;

            &:before {
                content: '';
                width: 30px;
                height: 2px;
                background: $highlight-color;
                position: absolute;
                bottom: 125%;
                left: 0;
            }
        }

        @mixin header-with-red-line {
            margin: 30px 0 10px 0;

            @include media-breakpoint-up(md) {
                position: relative;
                margin: 10px 0 24px 0;

                &:before {
                    content: '';
                    width: 30px;
                    height: 2px;
                    background: $highlight-color;
                    position: absolute;
                    bottom: 125%;
                    left: 0;
                }
            }
        }

        .h5 {
            @include font-open-sans-semi-medium(18px);
            @include header-with-red-line;
            text-transform: uppercase;
            color: $brand-color;
            color: #fff;
        }

        .section-links {
            a {
                @include font-open-sans-regular(16px);
                display: block;
                text-decoration: none;
                color: #bcbcbe;
                line-height: 28px;

                &:hover, &:focus {
                    color: #fff;
                }
            }
        }

        .header-contact {
            @include font-open-sans-semi-medium(18px);
            text-transform: uppercase;
            color: #fff;

            @include media-breakpoint-down(lg) {
                @include header-with-red-line;
            }

            @include media-breakpoint-up(lg) {
                margin-top: 10px;
            }
        }

        .text-phone {
            @include font-roboto-regular(22px);
            margin: 14px 0 8px;
            letter-spacing: 1px;

            a {
                text-decoration: none;
                color: #FFF;
            }
        }

        .mailto {
            @include font-open-sans-regular(16px);
            color: #fff;
            text-decoration: none;
            line-height: 28px;
        }

        .note-divider {
            margin-top: 30px;
            border-top: 1px solid #373742;

            @include media-breakpoint-up(md) {
                margin-top: 60px;
            }

            @include media-breakpoint-up(lg) {
                margin-top: 40px;
            }
        }

        .subscribe-section {
            color: #BCBCBE;
            font-size: 16px;
            margin-top: -6rem;

            @media (max-width: 990px) {
                margin-top: -105px;
            }

            @media (max-width: 768px) {
                margin-top: 20px;
            }


            .btn {
                &.subscribe-btn {
                    font-weight: 600;
                    padding: 8px 25px;
                }
            }

            label {
                &.error {
                    display: none !important;
                }
            }
        }

        li {
            &.list-item-nowrap {
                a {
                    white-space: nowrap;
                }
            }
        }

        .footnote {
            padding-top: 24px;
            padding-bottom: 22px;

            a {
                @include font-roboto-regular(14px);
                color: #bcbcbe;
                margin-right: 22px;
                text-decoration: none;
                white-space: break-spaces;

                &:hover, &:focus {
                    color: #fff;
                }

                @include media-breakpoint-down(lg) {
                    font-size: 16px;
                }
            }
        }
    }
</style>