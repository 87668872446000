import BaseRepository from "./base-repository";
import authDefaults from "@/constants/auth-constants";
import type { RefreshTokenModel } from "@/types/models/auth/refresh-token-model";
import type TokenModel from "@/types/models/auth/token-modal";
import qs from "qs";

class AuthRepository extends BaseRepository {
    async refreshToken(data: RefreshTokenModel) {
        return await this.post(authDefaults.tokenUri, qs.stringify(data), this.getUrlEncodedContentType({ baseURL: '' }));
    }

    async updateUserInfo() {
        return await this.get(authDefaults.userInfoUri, { baseURL: '' });
    }

    async logout() {
        return await this.get(authDefaults.logoutUri, { baseURL: '' });
    }

    async login(data: TokenModel) {
        return await this.post(authDefaults.tokenUri, qs.stringify(data), this.getUrlEncodedContentType({ baseURL: '' }));
    }

    getUrlEncodedContentType(options: any) {
        options.headers ||= {};

        options.headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

        return options;
    }
}

export default new AuthRepository();