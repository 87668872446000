import BaseRepository from "./base-repository";
import type ResetPasswordViewModel from "@/types/models/reset-password-view-model";
import type RegisterViewModel from "@/types/models/register-view-model";
import type ConfirmEmailPostViewModel from "@/types/models/confirm-email-post-view-model";
import type ChangePasswordModel from "@/types/models/change-password-model";
import type ChangeBaseInfoViewModel from "@/types/models/change-base-info-view-model";
import type UserBaseInfoViewModel from "@/types/models/store/user-base-info-view-model";

class AccountRepository extends BaseRepository {
    async forgotPassword(model: { email: string }) {
        try {
            return await this.post('/auth/forgotPassword', model);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async resetPassword(data: ResetPasswordViewModel) {
        try {
            return await this.post('/auth/resetPassword', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async register(data: RegisterViewModel) {
        try {
            return await this.post('/auth/register', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async getId() {
        try {
            return await this.get('/profile/getId');
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async getUserInfo() {
        try {
            return await this.get<UserBaseInfoViewModel>('/profile/GetUserInfo');
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async changeBaseInfo(data: ChangeBaseInfoViewModel) {
        try {
            return await this.post('/profile/ChangeBaseInfo', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async changeUserPassword(data: ChangePasswordModel) {
        try {
            return await this.post('/profile/ChangePassword', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async confirmEmail(data: ConfirmEmailPostViewModel) {
        try {
            return await this.post('/Auth/ConfirmEmail', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }
}

export default new AccountRepository();