import BaseRepository from "./base-repository";

class CustomerRepository extends BaseRepository {
    async getCustomerIdByUser(userId?: number | null) {
        try {
            return await this.get<number>('/customer/getCustomerIdByUser', { params: { userId } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async isCustomerTaxExempt(customerId: number) {
        try {
            return await this.get<boolean>('/customer/IsCustomerTaxExempt', { params: { customerId } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async hasTaxCertificate(customerId: number) {
        try {
            return await this.get<boolean>('/customer/hasTaxCertificate', { params: { customerId } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async getPersonalDiscount(customerId: number) {
        try {
            return await this.get<number>('/customer/getPersonalDiscount', { params: { customerId } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }
}

export default new CustomerRepository();