export default function (value: any) {
    if (!value)
        return true;

    if (typeof value === 'boolean')
        return false;

    else if (typeof value === 'number')
        return false;

    else if (typeof value === 'string')
        return !value.trim().length;

    else if (Array.isArray(value))
        return !value.length;

    else if (value instanceof Map || value instanceof Set)
        return value.size === 0;

    else if (typeof value === 'object')
        return !Object.keys(value).length;

    return false;
}