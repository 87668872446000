const authDefaults = {
    tokenUri: '/api/auth/login',
    logoutUri: '/api/auth/logout',
    logoutRedirectTo: '/login',
    unauthorizedRedirectTo: '/login',
    forbiddenRedirectTo: '/error-401-forbidden',
    userInfoUri: '/api/auth/userinfo',
    scope: 'openid offline_access email profile roles',
    rememberMeDuration: 14, //in days
    tokenStorageType: 'localStorage',
    tokenStorageNamespace: 'oidc-client'
};

export default authDefaults;