<template>
    <div>
        <StoreHeader />

        <div class="body-content">
            <div class="text-center">
                <div class="page-header">
                    <h1>404 Page Not Found</h1>
                </div>
                <p>The page is not found.</p>
            </div>
        </div>

        <StoreFooter />
    </div>
</template>

<script>
    export default {};
</script>