import type { FetchOptions, FetchResponse } from 'ohmyfetch';

export default class BaseRepository {
    async get<T = any>(url: string, options?: FetchOptions<T>): Promise<FetchResponse<T>> {
        return await this.request<T>('GET', url, options);
    }

    async post<T = any>(url: string, body?: any, options?: FetchOptions<T>): Promise<FetchResponse<T>> {
        return await this.request<T>('POST', url, { ...options, body });
    }

    async put<T = any>(url: string, body?: any, options?: FetchOptions<T>): Promise<FetchResponse<T>> {
        return await this.request<T>('PUT', url, { ...options, body });
    }

    async delete<T = any>(url: string, options?: FetchOptions<T>): Promise<FetchResponse<T>> {
        return await this.request<T>('DELETE', url, options);
    }

    private async request<T = any>(method: string, url: string, options?: FetchOptions<T>): Promise<FetchResponse<T>> {
        return await useNuxtApp().$api<T>(url, { ...options, method });
    }
}