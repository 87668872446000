import type { BlobUrlViewModel } from "@/types/models/cart/cart-item-view-model";

export const useDropzone = () => {
    const attachments = ref<BlobUrlViewModel[]>([]);

    function fileUploaded(data: BlobUrlViewModel) {
        attachments.value.push(data);
    }

    function fileRemoved(file: File) {
        const index = attachments.value.findIndex(u => u.file === file);

        if (index !== -1)
            attachments.value.splice(index, 1);
    }    

    return { attachments, fileUploaded, fileRemoved }
};