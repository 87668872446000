export const useUserStore = defineStore('user', {
    state: () => {
        return {
            accessToken: null,
            refreshToken: null,
            userInfo: null,
            loginProviders: null
        }
    },

    getters: {
        isAuthenticated: (state) => state.accessToken != null,

        hasLocalAccount() {
            return this.isAuthenticated && this.userInfo && this.userInfo.has_local_account;
        },

        isLoggedInAsAnotherUser() {
            return this.isAuthenticated && this.userInfo && this.userInfo.admin_username;
        }
    },

    actions: {
        isInRole(roles) {
            if (!this.userInfo)
                return false;

            let rolesToMatch = typeof (roles) === 'string' ? [roles] : roles;

            return this.userInfo.roles.some((r) => rolesToMatch.includes(r));
        },

        reset() {
            this.$patch({
                accessToken: null,
                refreshToken: null,
                userInfo: null
            });
        }      
    },

    persist: {
        storage: getStorage('localStorage')
    }
});

function getStorage(storageType) {
    switch (storageType) {
        case 'cookieStorage':
            return persistedState.cookiesWithOptions({
                sameSite: 'strict',
                domain: window.location.hostname,
                expires: null,
                path: '/',
                secure: false
            });

        case 'sessionStorage':
            return persistedState.sessionStorage;

        default:
            return persistedState.localStorage;
    }
}