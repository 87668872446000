import type ShippingFormViewModel from "@/types/models/order/shipping-form-view-model";
import type ShippingOptionFormViewModel from "@/types/models/order/shipping-option-form-view-model";
import type OrderAddressModel from "@/types/models/order/order-addres-model";
import type OrderConfirmationFormViewModel from "@/types/models/order/order-confirmation-form-view-model";
import type OrderPaymentFormViewModel from "@/types/models/order/order-payment-form-view-model";
import type CustomerFormViewModel from "@/types/models/customer/customer-form-view-model";
import type StripeChargeResponse from "@/types/models/stripe/stripe-charge-response";
import type StripeDataModel from "@/types/models/stripe/stripe-data-model";
import type OrderViewModel from "@/types/models/order/order-view-model";
import type OrderInitFormViewModel from "@/types/models/order/order-init-form-view-model";
import type { SelectListOption } from "@/types/models/select-list-item";
import type OrderAddressEditFormViewModel from "@/types/models/address/order-address-edit-form-view-model";
import type { OrderSaveViewModel } from "@/types/models/order/order-save-quantity-view-model";
import type BlobUploadViewModel from "@/types/models/order/blob-upload-view-model";
import type { DeleteCartItemViewModel, EditCartViewModel } from "@/types/models/cart/cart-item-view-model";
import type OrderProfileViewModel from "@/types/models/order/order-profile-view-model";
import type { SaveAdditionalInfoViewModel } from "@/types/models/order/save-additional-info-view-model";
import BaseRepository from './base-repository';

class OrderRepository extends BaseRepository {
    async createOrder(data: OrderInitFormViewModel) {
        try {
            await this.post('/order/createOrder', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async saveOrderBlobUrl(model: BlobUploadViewModel) {
        try {
            await this.post('/order/saveOrderBlobUrl', model);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async syncShoppingCartItems() {
        try {
            await this.post('/order/syncShoppingCartItems');
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async getSecureCustomer() {
        try {
            return await this.get('/order/getSecureCustomer');
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async resetOrderBlobUrl() {
        try {
            await this.post('/order/resetOrderBlobUrl');
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async getSecureOrders() {
        try {
            return await this.get<OrderProfileViewModel[]>('/order/getSecureOrders');
        }
        catch (error) {
            throw new apiError(error);
        }
    }
    
    async getOrder() {
        try {
            return await this.get<OrderViewModel>('/order/getCurrentOrder');
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async getSecureOrder(id: number) {
        try {
            return await this.get<OrderViewModel>('/order/getSecureOrder', { params: { id } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async createCountryData() {
        try {
            let countries: SelectListOption[]; 
            countries = await this.get('/order/getCountries');
            countries.sort((x,y)=>{ return x.text === 'Canada' ? -1 : y.text === 'Canada' ? 1 : 0; });
            countries.sort((x,y)=>{ return x.text === 'United States' ? -1 : y.text === 'United States' ? 1 : 0; });
            // @ts-ignore
            countries.splice(2, 0, {text: 'Other Countries', value: '', disabled: true});
            
            return countries;
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async createStateData(id: number) {
        try {
            return await this.get('/order/getCountryStates', { params: { id } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async editProductInOrderAndCart(model: EditCartViewModel) {
        try {
            await this.post('/order/editProductInOrderAndCart', model);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async removeProductFromOrderAndCart(model: DeleteCartItemViewModel) {
        try {
            await this.post('/order/removeProductFromOrderAndCart', model);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async addCustomerToOrder(data: CustomerFormViewModel) {
        try {
            await this.post('/order/addCustomerToOrder', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async getCustomer() {
        try {
            return await this.get<CustomerFormViewModel>('/order/getCustomer');
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async getCustomerCompanyName(customerId: number) {
        try {
            return await this.get<string>('/order/getCustomerCompanyName', { params: { customerId } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async addStepToOrder(stepId: number) {
        try {
            await this.post('/order/AddStepToOrder/' + stepId);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async addAddressToOrder(data: OrderAddressModel) {
        try {
            await this.post('/order/addAddressToOrder', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async createShippingData(data: ShippingFormViewModel) {
        try {
            await this.post('/order/createShippingData', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async getShippingOptions(isStore = false, freezeProtection = false, liftgate = false) {
        try {
            return await this.get('/order/getShippingOptions', { params: { isStore: isStore, freezeProtection: freezeProtection, liftgate: liftgate } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async estimateShipping(model: ShippingFormViewModel) {
        try {
            return await this.post('/order/estimateShipping', model);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async addShippingOptionToOrder(data: ShippingOptionFormViewModel) {
        try {
            await this.post('/order/addShippingOptionToOrder', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async getStripePaymentData() {
        try {
            return await this.get<StripeDataModel>('/order/getStripePaymentData');
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async chargeUsingStripe(token: string) {
        try {
            return await this.get<StripeChargeResponse>('/order/chargeUsingStripe', { params: { token } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async saveOrderPayment(data: OrderPaymentFormViewModel) {
        try {
            await this.post('/order/saveOrderPayment', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async getOrderSalesTaxRate() {
        try {
            return await this.get<number>('/order/getOrderSalesTaxRate');
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async stateIsTaxable(stateId: number) {
        try {
            return await this.get<boolean>('/order/stateIsTaxable', { params: { stateId } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async confirmOrder(data: OrderConfirmationFormViewModel) {
        try {
            await this.post('/order/confirmOrder', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async saveOrUpdateOrder(isAdminPanel = true, orderNote = '') {
        try {
            return await this.post('/order/saveOrUpdateOrder', { isAdminPanel: isAdminPanel, note: orderNote } as OrderSaveViewModel);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async EditOrderAddress(data: OrderAddressEditFormViewModel) {
        try {
            return await this.post('/order/editOrderAddress', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async addShipFromAddressToOrder(warehouseAddressId: number) {
        try {
            return await this.get('/order/addShipFromAddressToOrder', { params: { warehouseAddressId } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async saveAdditionalInfo(data: SaveAdditionalInfoViewModel) {
        try {
            await this.post('/order/saveAdditionalInfo', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async getLatestOrderId(customerId: number, billingEmail: string) {
        try {
            return await this.get<number | null>('/order/getLatestOrderId', { params: { customerId, billingEmail } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }
}

export default new OrderRepository();