import type { SelectListOption } from "@/types/models/select-list-item";
import type SpecificProductViewModel from "@/types/models/product/specific-product-view-model";
import BaseRepository from "./base-repository";

class ProductRepository extends BaseRepository {
    async getAllProductVariants() {
        try {
            return await this.get<SelectListOption[]>('/product/getAllProductVariants');
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async getSpecificProductVariants(isFRClear: boolean) {
        try {
            return await this.get<SpecificProductViewModel[]>('/product/getSpecificProductVariants', { params: { isFRClear } });
        }
        catch (error) {
            throw new apiError(error);
        }
    }
}

export default new ProductRepository();