﻿<template>
    <div :class="['mb-3 form-group', {'is-invalid': validator.$error, 'is-valid': !validator.$error && validator.$dirty}]">
        <label v-if="label" :for="labelForAttributeValue" :class="['form-label', {required: showLabelAsRequired}]">{{ label }}</label>
        <slot></slot>
        <div :class="{ 'invalid-feedback': validator.$error, 'valid-feedback': !validator.$error  }" v-if="validator.$error && !hideErrorMessage">
            <div v-for="error in validator.$errors" :key="error.$message">
                {{ error.$message }}
            </div>
        </div>
    </div>
</template>

<script setup>
    const props = defineProps({
        validator: {
            type: Object,
            default: () => ({
                $dirty: false,
                $error: false,
                $invalid: true,
                $pending: false,
                $params: []
            })
        },

        label: {
            type: String,
            default: ''
        },

        labelFor: {
            type: String,
            default: null
        },

        forceRequiredLabel: {
            type: Boolean,
            default: false
        },

        hideErrorMessage: {
            type: Boolean,
            default: false
        }
    })

    const labelForAttributeValue = ref('');

    onMounted(() => {
        nextTick(() => {
            setLabelForAttributeValue();
        })
    });

    onUpdated(() => {
        nextTick(() => {
            setLabelForAttributeValue();
        })
    });

    watch(() => props.labelFor, () => {
        setLabelForAttributeValue();
    })

    const showLabelAsRequired = computed(() => {
        if (props.forceRequiredLabel)
            return true;

        return props.validator.required != null;
    })

    function setLabelForAttributeValue() {
        if (props.labelFor) {
            labelForAttributeValue.value = props.labelFor;
            return;
        }

        return "";
    }
</script>