import { default as a_45guide_45to_45full_45compliance_45in_45intumescent_45paintg4WhdQPsq5Meta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/a-guide-to-full-compliance-in-intumescent-paint.vue?macro=true";
import { default as a_45review_45of_45intumescent_45paintGuFeLPlIjyMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/a-review-of-intumescent-paint.vue?macro=true";
import { default as companyqEZsIkKMwFMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/about/company.vue?macro=true";
import { default as achieve_452_45hour_45fire_45rating_45over_45drywall_45using_45intumescent_45paint8VrNNIJEwkMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/achieve-2-hour-fire-rating-over-drywall-using-intumescent-paint.vue?macro=true";
import { default as applying_45fire_45rated_45intumescent_45paint_45on_45gypsumx0JAB8mhHjMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/applying-fire-rated-intumescent-paint-on-gypsum.vue?macro=true";
import { default as applying_45fire_45rated_45intumescent_45paint_45on_45steelYzM9CFH4VwMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/applying-fire-rated-intumescent-paint-on-steel.vue?macro=true";
import { default as applying_45fire_45rated_45intumescent_45paint_45on_45wood9vKJQhz1coMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/applying-fire-rated-intumescent-paint-on-wood.vue?macro=true";
import { default as applying_45fire_45retardant_45coatings_45on_45woodyBxZsDxigZMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/applying-fire-retardant-coatings-on-wood.vue?macro=true";
import { default as applying_45flame_45retardant_45coatings_45on_45fabricsgxvcGmJc0NMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/applying-flame-retardant-coatings-on-fabrics.vue?macro=true";
import { default as articlesbsmyy9OBBSMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/articles.vue?macro=true";
import { default as reset_45passwordtIRXfHIx6jMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/auth/reset-password.vue?macro=true";
import { default as building_45with_45fire_45safety_45in_45mindRRZYehKCYMMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/building-with-fire-safety-in-mind.vue?macro=true";
import { default as cartMi7JcxAh20Meta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/cart.vue?macro=true";
import { default as checkout6PZmaSkJx7Meta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/checkout.vue?macro=true";
import { default as company_45news7zXZHhZMvRMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/company-news.vue?macro=true";
import { default as comprehensive_45guide_45to_45intumescent_45paintijEY1F6Mp8Meta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/comprehensive-guide-to-intumescent-paint.vue?macro=true";
import { default as confirm_45emailOI5vpx2c0RMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/confirm-email.vue?macro=true";
import { default as contact_45usiqZD87bSqhMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/contact-us.vue?macro=true";
import { default as infoe8ICQDWy1pMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/customer/info.vue?macro=true";
import { default as engineering_45judgment_45importance_45of_45fireproofing3RF8qPiK6MMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/engineering-judgment-importance-of-fireproofing.vue?macro=true";
import { default as error_45401_45forbiddenaqLLmK87ynMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/error-401-forbidden.vue?macro=true";
import { default as error_45404_45not_45foundLEwF8tRtMZMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/error-404-not-found.vue?macro=true";
import { default as faqng5a5JUSGiMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/faq.vue?macro=true";
import { default as finish_45rating_45on_45drywallLLDDZkCK4cMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/finish-rating-on-drywall.vue?macro=true";
import { default as fire_45retardant_45clear_45sprayxY8fgEWBEsMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/fire-retardant-clear-spray.vue?macro=true";
import { default as fireproofing_45methods_45for_45woodhmUKshI8KHMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/fireproofing-methods-for-wood.vue?macro=true";
import { default as flameoff_45fire_45barrier_45paint_45for_45haunted_45houseseLSdbut37FMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/flameoff-fire-barrier-paint-for-haunted-houses.vue?macro=true";
import { default as flameoff_45partnershipKT78X6cfLzMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/flameoff-partnership.vue?macro=true";
import { default as how_45to_45achieve_45a_45higher_45fire_45rating_45on_45gypsum_45metal_45and_45wood_45with_45intumescent_45paintwpUClfFBCGMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/how-to-achieve-a-higher-fire-rating-on-gypsum-metal-and-wood-with-intumescent-paint.vue?macro=true";
import { default as increasing_45fire_45safety_45on_45upholstered_45furniturenhrWuNYRlyMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/increasing-fire-safety-on-upholstered-furniture.vue?macro=true";
import { default as indexq5nJT9yGHYMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/index.vue?macro=true";
import { default as intumescent_45fire_45resistant_45paint6fSqL7kZE9Meta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-fire-resistant-paint.vue?macro=true";
import { default as _2_45hour_45fire_45protection_45on_45steel_45beams_45with_45LformaFE1aZz6ZSZMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/2-hour-fire-protection-on-steel-beams-with-Lforma.vue?macro=true";
import { default as ambling_45construction_45group_45atlanta_45georgiafcxBhXFmoEMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/ambling-construction-group-atlanta-georgia.vue?macro=true";
import { default as amu_45surgeonsUVYmsSDaCwMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/amu-surgeons.vue?macro=true";
import { default as arc_45partners_45roswell_45georgiaQr1a6G4p6DMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/arc-partners-roswell-georgia.vue?macro=true";
import { default as bartson_45construction_45atlanta_45georgiaL0vkJY1ftwMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/bartson-construction-atlanta-georgia.vue?macro=true";
import { default as bell_45clark_45nashville_45tnFijEuUThbkMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/bell-clark-nashville-tn.vue?macro=true";
import { default as bramble_45theater_451_45hour_45fire_45rated_45cieling_45assemblyp3NyD2WesiMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/bramble-theater-1-hour-fire-rated-cieling-assembly.vue?macro=true";
import { default as colon_45eye_45clinicXkvVqISbuUMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/colon-eye-clinic.vue?macro=true";
import { default as giland_45townhomesKrVYaJH5MuMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/giland-townhomes.vue?macro=true";
import { default as indexxHksksZHlaMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/index.vue?macro=true";
import { default as lenox_45propertiesk9A431SixjMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/lenox-properties.vue?macro=true";
import { default as market_45street_45hotel_45augusta_45georgia4Sk0PQqJ7LMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/market-street-hotel-augusta-georgia.vue?macro=true";
import { default as memorial_45building_45deerfield_45academy_45deerfield_45massachusettsvEgyGc8TrSMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/memorial-building-deerfield-academy-deerfield-massachusetts.vue?macro=true";
import { default as turner_45barn7MC3IwN0zsMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/turner-barn.vue?macro=true";
import { default as urban_45housing_45authoritySvPyQ8xBbUMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/urban-housing-authority.vue?macro=true";
import { default as world_45cup_452022_45stadium_45achievs_452_45hour_45fire_45rating_45on_45gypsum5uO1vm1eQ7Meta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/world-cup-2022-stadium-achievs-2-hour-fire-rating-on-gypsum.vue?macro=true";
import { default as leedhAa6ezzQ67Meta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/leed.vue?macro=true";
import { default as loginh3P7fvfwsnMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/login.vue?macro=true";
import { default as _91id_93dH7rbmptjxMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/orderdetails/[id].vue?macro=true";
import { default as passwordrecoveryLtQk46QGqHMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/passwordrecovery.vue?macro=true";
import { default as privacy_45policylPAIdGbLJlMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/privacy-policy.vue?macro=true";
import { default as register1mA6rhA484Meta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/register.vue?macro=true";
import { default as return_45policyIym0PPOGCpMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/return-policy.vue?macro=true";
import { default as shipping_45policydKSrWddMcjMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/shipping-policy.vue?macro=true";
import { default as steel_45coverage_45calculator8v2EBl5C36Meta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/steel-coverage-calculator.vue?macro=true";
import { default as steel_45dimensions_45tableb56E8FyGwWMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/steel-dimensions-table.vue?macro=true";
import { default as term_45of_45useCqmVu2uupSMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/term-of-use.vue?macro=true";
import { default as terms_45and_45conditionsGSyrhnvFqjMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/terms-and-conditions.vue?macro=true";
import { default as terms_45of_45serviceWPWITiiIMHMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/terms-of-service.vue?macro=true";
import { default as testing57GVVsyqdTMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/testing.vue?macro=true";
import { default as _91id_93ZZtn7dzgmCMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/thankyou/[id].vue?macro=true";
import { default as understanding_45canadian_45standards_45of_45fire_45safety1A0HorujM8Meta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/understanding-canadian-standards-of-fire-safety.vue?macro=true";
import { default as when_45fireproofing_45building_45spray_45is_45kingx7fZj8DzDCMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/when-fireproofing-building-spray-is-king.vue?macro=true";
import { default as why_45the_45size_45of_45steel_45is_45important_45in_45fre_45proofingIAnWuv6WsLMeta } from "C:/Projects/HUB/src/PublicSite/client-app/pages/why-the-size-of-steel-is-important-in-fre-proofing.vue?macro=true";
export default [
  {
    name: "a-guide-to-full-compliance-in-intumescent-paint",
    path: "/a-guide-to-full-compliance-in-intumescent-paint",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/a-guide-to-full-compliance-in-intumescent-paint.vue").then(m => m.default || m)
  },
  {
    name: "a-review-of-intumescent-paint",
    path: "/a-review-of-intumescent-paint",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/a-review-of-intumescent-paint.vue").then(m => m.default || m)
  },
  {
    name: "about-company",
    path: "/about/company",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/about/company.vue").then(m => m.default || m)
  },
  {
    name: "achieve-2-hour-fire-rating-over-drywall-using-intumescent-paint",
    path: "/achieve-2-hour-fire-rating-over-drywall-using-intumescent-paint",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/achieve-2-hour-fire-rating-over-drywall-using-intumescent-paint.vue").then(m => m.default || m)
  },
  {
    name: "applying-fire-rated-intumescent-paint-on-gypsum",
    path: "/applying-fire-rated-intumescent-paint-on-gypsum",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/applying-fire-rated-intumescent-paint-on-gypsum.vue").then(m => m.default || m)
  },
  {
    name: "applying-fire-rated-intumescent-paint-on-steel",
    path: "/applying-fire-rated-intumescent-paint-on-steel",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/applying-fire-rated-intumescent-paint-on-steel.vue").then(m => m.default || m)
  },
  {
    name: "applying-fire-rated-intumescent-paint-on-wood",
    path: "/applying-fire-rated-intumescent-paint-on-wood",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/applying-fire-rated-intumescent-paint-on-wood.vue").then(m => m.default || m)
  },
  {
    name: "applying-fire-retardant-coatings-on-wood",
    path: "/applying-fire-retardant-coatings-on-wood",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/applying-fire-retardant-coatings-on-wood.vue").then(m => m.default || m)
  },
  {
    name: "applying-flame-retardant-coatings-on-fabrics",
    path: "/applying-flame-retardant-coatings-on-fabrics",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/applying-flame-retardant-coatings-on-fabrics.vue").then(m => m.default || m)
  },
  {
    name: "articles",
    path: "/articles",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "building-with-fire-safety-in-mind",
    path: "/building-with-fire-safety-in-mind",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/building-with-fire-safety-in-mind.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "company-news",
    path: "/company-news",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/company-news.vue").then(m => m.default || m)
  },
  {
    name: "comprehensive-guide-to-intumescent-paint",
    path: "/comprehensive-guide-to-intumescent-paint",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/comprehensive-guide-to-intumescent-paint.vue").then(m => m.default || m)
  },
  {
    name: "confirm-email",
    path: "/confirm-email",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/confirm-email.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "customer-info",
    path: "/customer/info",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/customer/info.vue").then(m => m.default || m)
  },
  {
    name: "engineering-judgment-importance-of-fireproofing",
    path: "/engineering-judgment-importance-of-fireproofing",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/engineering-judgment-importance-of-fireproofing.vue").then(m => m.default || m)
  },
  {
    name: "error-401-forbidden",
    path: "/error-401-forbidden",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/error-401-forbidden.vue").then(m => m.default || m)
  },
  {
    name: "error-404-not-found",
    path: "/error-404-not-found",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/error-404-not-found.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "finish-rating-on-drywall",
    path: "/finish-rating-on-drywall",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/finish-rating-on-drywall.vue").then(m => m.default || m)
  },
  {
    name: "fire-retardant-clear-spray",
    path: "/fire-retardant-clear-spray",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/fire-retardant-clear-spray.vue").then(m => m.default || m)
  },
  {
    name: "fireproofing-methods-for-wood",
    path: "/fireproofing-methods-for-wood",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/fireproofing-methods-for-wood.vue").then(m => m.default || m)
  },
  {
    name: "flameoff-fire-barrier-paint-for-haunted-houses",
    path: "/flameoff-fire-barrier-paint-for-haunted-houses",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/flameoff-fire-barrier-paint-for-haunted-houses.vue").then(m => m.default || m)
  },
  {
    name: "flameoff-partnership",
    path: "/flameoff-partnership",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/flameoff-partnership.vue").then(m => m.default || m)
  },
  {
    name: "how-to-achieve-a-higher-fire-rating-on-gypsum-metal-and-wood-with-intumescent-paint",
    path: "/how-to-achieve-a-higher-fire-rating-on-gypsum-metal-and-wood-with-intumescent-paint",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/how-to-achieve-a-higher-fire-rating-on-gypsum-metal-and-wood-with-intumescent-paint.vue").then(m => m.default || m)
  },
  {
    name: "increasing-fire-safety-on-upholstered-furniture",
    path: "/increasing-fire-safety-on-upholstered-furniture",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/increasing-fire-safety-on-upholstered-furniture.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-fire-resistant-paint",
    path: "/intumescent-fire-resistant-paint",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-fire-resistant-paint.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-2-hour-fire-protection-on-steel-beams-with-Lforma",
    path: "/intumescent-paint-case-studies/2-hour-fire-protection-on-steel-beams-with-Lforma",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/2-hour-fire-protection-on-steel-beams-with-Lforma.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-ambling-construction-group-atlanta-georgia",
    path: "/intumescent-paint-case-studies/ambling-construction-group-atlanta-georgia",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/ambling-construction-group-atlanta-georgia.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-amu-surgeons",
    path: "/intumescent-paint-case-studies/amu-surgeons",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/amu-surgeons.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-arc-partners-roswell-georgia",
    path: "/intumescent-paint-case-studies/arc-partners-roswell-georgia",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/arc-partners-roswell-georgia.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-bartson-construction-atlanta-georgia",
    path: "/intumescent-paint-case-studies/bartson-construction-atlanta-georgia",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/bartson-construction-atlanta-georgia.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-bell-clark-nashville-tn",
    path: "/intumescent-paint-case-studies/bell-clark-nashville-tn",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/bell-clark-nashville-tn.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-bramble-theater-1-hour-fire-rated-cieling-assembly",
    path: "/intumescent-paint-case-studies/bramble-theater-1-hour-fire-rated-cieling-assembly",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/bramble-theater-1-hour-fire-rated-cieling-assembly.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-colon-eye-clinic",
    path: "/intumescent-paint-case-studies/colon-eye-clinic",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/colon-eye-clinic.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-giland-townhomes",
    path: "/intumescent-paint-case-studies/giland-townhomes",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/giland-townhomes.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies",
    path: "/intumescent-paint-case-studies",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/index.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-lenox-properties",
    path: "/intumescent-paint-case-studies/lenox-properties",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/lenox-properties.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-market-street-hotel-augusta-georgia",
    path: "/intumescent-paint-case-studies/market-street-hotel-augusta-georgia",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/market-street-hotel-augusta-georgia.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-memorial-building-deerfield-academy-deerfield-massachusetts",
    path: "/intumescent-paint-case-studies/memorial-building-deerfield-academy-deerfield-massachusetts",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/memorial-building-deerfield-academy-deerfield-massachusetts.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-turner-barn",
    path: "/intumescent-paint-case-studies/turner-barn",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/turner-barn.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-urban-housing-authority",
    path: "/intumescent-paint-case-studies/urban-housing-authority",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/urban-housing-authority.vue").then(m => m.default || m)
  },
  {
    name: "intumescent-paint-case-studies-world-cup-2022-stadium-achievs-2-hour-fire-rating-on-gypsum",
    path: "/intumescent-paint-case-studies/world-cup-2022-stadium-achievs-2-hour-fire-rating-on-gypsum",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/intumescent-paint-case-studies/world-cup-2022-stadium-achievs-2-hour-fire-rating-on-gypsum.vue").then(m => m.default || m)
  },
  {
    name: "leed",
    path: "/leed",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/leed.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orderdetails-id",
    path: "/orderdetails/:id()",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/orderdetails/[id].vue").then(m => m.default || m)
  },
  {
    name: "passwordrecovery",
    path: "/passwordrecovery",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/passwordrecovery.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "return-policy",
    path: "/return-policy",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "shipping-policy",
    path: "/shipping-policy",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/shipping-policy.vue").then(m => m.default || m)
  },
  {
    name: "steel-coverage-calculator",
    path: "/steel-coverage-calculator",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/steel-coverage-calculator.vue").then(m => m.default || m)
  },
  {
    name: "steel-dimensions-table",
    path: "/steel-dimensions-table",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/steel-dimensions-table.vue").then(m => m.default || m)
  },
  {
    name: "term-of-use",
    path: "/term-of-use",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/term-of-use.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "testing",
    path: "/testing",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/testing.vue").then(m => m.default || m)
  },
  {
    name: "thankyou-id",
    path: "/thankyou/:id()",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/thankyou/[id].vue").then(m => m.default || m)
  },
  {
    name: "understanding-canadian-standards-of-fire-safety",
    path: "/understanding-canadian-standards-of-fire-safety",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/understanding-canadian-standards-of-fire-safety.vue").then(m => m.default || m)
  },
  {
    name: "when-fireproofing-building-spray-is-king",
    path: "/when-fireproofing-building-spray-is-king",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/when-fireproofing-building-spray-is-king.vue").then(m => m.default || m)
  },
  {
    name: "why-the-size-of-steel-is-important-in-fre-proofing",
    path: "/why-the-size-of-steel-is-important-in-fre-proofing",
    meta: {"middleware":["auth"]},
    component: () => import("C:/Projects/HUB/src/PublicSite/client-app/pages/why-the-size-of-steel-is-important-in-fre-proofing.vue").then(m => m.default || m)
  }
]