import {
  faEllipsisVertical as freeFasFaEllipsisVertical,
  faUser as freeFasFaUser,
  faPowerOff as freeFasFaPowerOff,
  faBasketShopping as freeFasFaBasketShopping,
  faAnglesRight as freeFasFaAnglesRight,
  faAnglesLeft as freeFasFaAnglesLeft,
  faPrint as freeFasFaPrint,
  faPen as freeFasFaPen,
  faTrash as freeFasFaTrash,
  faArrowDownLong as freeFasFaArrowDownLong
} from '@fortawesome/free-solid-svg-icons'

import {
  faLinkedin as freeFabFaLinkedin,
  faFacebook as freeFabFaFacebook,
  faTwitter as freeFabFaTwitter,
  faYoutube as freeFabFaYoutube,
  faInstagram as freeFabFaInstagram
} from '@fortawesome/free-brands-svg-icons'

export default {freeFasFaEllipsisVertical, freeFasFaUser, freeFasFaPowerOff, freeFasFaBasketShopping, freeFasFaAnglesRight, freeFasFaAnglesLeft, freeFasFaPrint, freeFasFaPen, freeFasFaTrash, freeFasFaArrowDownLong, freeFabFaLinkedin, freeFabFaFacebook, freeFabFaTwitter, freeFabFaYoutube, freeFabFaInstagram}