import type SubscribeViewModel from "@/types/models/subscribe/subscribe-view-model";
import BaseRepository from "./base-repository";
import type { ContactUsViewModel, OrderNowViewModel } from "@/types/models/email/contact-us-view-model";

class MessageRepository extends BaseRepository {
    async subscribeNewsletter(model: SubscribeViewModel) {
        try {
            return await this.post('/mail/subscribeNewsletter', model);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async verifyRecaptcha(response: string) {
        try {
            return await this.post<{ success: Boolean, challenge_ts: Date, hostname: String, "error-codes"?: [] }>('/googleRecaptcha/verify', { response });
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async contactUsSend(data: ContactUsViewModel) {
        try {
            await this.post('/mail/contactUsSend', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }

    async freeQuateSend(data: OrderNowViewModel) {
        try {
            await this.post('/mail/freeQuateSend', data);
        }
        catch (error) {
            throw new apiError(error);
        }
    }
}

export default new MessageRepository();