export const useRecaptcha = () => {
    const googleRecaptchaKey = "6LfxXfUpAAAAAN08ZFwnCQVkcAbNaBc1CC2nR8ms";

    function renderRecaptchaScript() {
        let script = document.getElementById('recaptcha-script');

        if (!script) {
            script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            script.src = `https://www.google.com/recaptcha/api.js?render=${googleRecaptchaKey}`;

            document.head.appendChild(script);
        }
    }

    function destroyRecaptchaScript() {
        let script = document.getElementById('recaptcha-script');

        if (script)
            script.remove();
    }

    return {
        renderRecaptchaScript,
        destroyRecaptchaScript,
        googleRecaptchaKey
    };
};