<template>
    <div v-if="errorsArr.length > 0">
        <div class="alert alert-danger errors">
            <p v-for="err in errorsArr" :key="err">{{ err }}</p>
        </div>
    </div>
</template>

<script lang="ts" setup>
    const props = defineProps({
        errors: {
            type: [String, Array<string>],
            required: true,
            default: function () {
                return [];
            }
        },
        success: null
    });
    const errorsArr = ref<Nullable<string | Array<string>>>([]);

    watch(() => props.errors, () => {
        errorsArr.value = Array.isArray(props.errors) ? props.errors : [props.errors];
    })
</script>

<style lang="scss" scoped>
    .errors {
        > p {
            margin-bottom: 0.75rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>