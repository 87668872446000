import useVuelidate from '@vuelidate/core';

export const useFormModal = (validations?: any, form?: any, onSubmit?: () => Promise<void>, beforeShow?: (args?: any) => Promise<void>, successPayload?: () => Promise<void>)  => {
    const { emit } = getCurrentInstance()!;
    const showModal = ref(false);
    const errors = ref<string[]>([]);
    const loading = ref(false);
    const formValue = unref(form);
    const defaultValues = { ...formValue };

    const v$ = useVuelidate(validations, form, { $stopPropagation: true });

    const messagesOverride = ref({
        minValue: "{attribute} field’s value should be greater than {min}.",
        maxValue: "{attribute} field’s value should be less than {max}.",
        maxLength: "{attribute} field’s maximum length should be less than {max} characters.",
        numeric: "{attribute} field should have numeric value."
    });

    function resetData() {
        Object.assign(formValue, defaultValues);
        if (v$) v$.value.$reset();
    }

    async function show(args?: any) {
        resetData();

        if (beforeShow)
            await beforeShow(args);

        showModal.value = true;
    }

    function close() {
        showModal.value = false;
    }

    async function submit() {
        if (v$ && v$.value.$invalid) {
            v$.value.$touch();
            return;
        }

        errors.value = [];
        loading.value = true;

        try {
            if (onSubmit)
                await onSubmit();

            showModal.value = false;

            if (successPayload)
                emit('success', successPayload);
        }
        catch (error) {
            errors.value = (error as any).customErrors;
        }
        finally {
            loading.value = false;
        }
    }

    return { showModal, errors, loading, show, close, submit, resetData, messagesOverride, v$ }
}